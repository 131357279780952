import React from "react";
import "./MissionAndVission.css";
import { motion } from "framer-motion";

const missionVariants = {
  hidden: {
    opacity: 0,
    x: "-30px",
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      type: "tween",
      duration: 0.5,
      delay: 0.5,
      ease: "easeInOut",
    },
  },
};
const objectiveVariants = {
  hidden: {
    opacity: 0,
    y: "-30px",
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "tween",
      duration: 0.5,
      delay: 0.5,
      ease: "easeInOut",
    },
  },
};
const visionVariants = {
  hidden: {
    opacity: 0,
    x: "30px",
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      type: "tween",
      duration: 0.5,
      delay: 0.5,
      ease: "easeInOut",
    },
  },
};

const MissionAndVision = () => {
  return (
    <>
      <div className="mission-vision-body">
        <motion.div
          className="vision"
          variants={visionVariants}
          initial="hidden"
          whileInView="visible"
        >
          <h3>Vision</h3>
          <p>
            Achieving excellence in quality education through continuous
            improvement of teaching, learning, research and holistic support
            services to make ISU an eminent learning center of international
            standard.{" "}
          </p>
        </motion.div>
        <motion.div
          className="mission"
          variants={missionVariants}
          initial="hidden"
          whileInView="visible"
        >
          <h3>Mission</h3>
          <p>
            IQAC at ISU is committed to create an enabling environment and a
            quality culture in all areas of teaching and learning related to
            tertiary level education in terms of governance, academic standard,
            curriculum, assessment, development, research, and continual
            improvement to meet the needs of the stakeholders including
            teachers, students, alumni, employers, and the society at large.
          </p>
        </motion.div>

        <motion.div
          className="objective"
          variants={objectiveVariants}
          initial="hidden"
          whileInView="visible"
        >
          <h3>Objectives of the IQAC</h3>
          <ul>
            <li>
              To develop a system for conscious, consistent and catalytic action
              to improve the academic and administrative performance of the
              universities.
            </li>
            <li>
              To undertake measures for quality enhancement and excellence in
              teaching-learning through internationalization as well as to
              institutionalize the best practices of QA activities.
            </li>
          </ul>
        </motion.div>
      </div>
    </>
  );
};

export default MissionAndVision;
