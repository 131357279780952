import React, { useEffect, useRef, useState } from "react";
import "./Slider.css";
import { sliderData } from "../../data/sliderData";
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from "react-icons/fa";
import { motion } from "framer-motion";

const Slider = ({ interval = 6000 }) => {
  const [slider, setSliderData] = useState(sliderData);

  const [currentSlide, setCurrentSlide] = useState(0);
  const sDataLenght = sliderData.length;
  const sliderInterval = useRef;
  const prev = () => {
    stopSlideTimer();
    const index = currentSlide > 0 ? currentSlide - 1 : sDataLenght - 1;
    setCurrentSlide(index);
  };
  const next = () => {
    stopSlideTimer();
    const index = currentSlide < sDataLenght - 1 ? currentSlide + 1 : 0;
    setCurrentSlide(index);
  };

  const startSlideTimer = () => {
    stopSlideTimer();
    sliderInterval.current = setInterval(() => {
      setCurrentSlide((currentSlide) =>
        currentSlide < sDataLenght - 1 ? currentSlide + 1 : 0
      );
    }, interval);
  };

  const stopSlideTimer = () => {
    if (sliderInterval.current) {
      clearInterval(sliderInterval.current);
    }
  };

  const switchIndex = (index) => {
    startSlideTimer();
    setCurrentSlide(index);
  };

  useEffect(() => {
    startSlideTimer();
    return () => stopSlideTimer();
  }, []);

  const sliderVariants = {
    hidden: {
      opacity: 0,
      y: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        type: "tween",
        duration: 2,
        delay: 0,
        ease: "easeIn",
      },
    },
    exit: {
      opacity: 0,
      transition: {
        type: "tween",
        duration: 2,
        easeout: "easeOut",
      },
    },
  };
  return (
    <>
      <div className="slider-container">
        <div className="slider-image">
          <motion.img
            key={currentSlide}
            src={slider[currentSlide].image}
            onMouseOver={stopSlideTimer}
            onMouseLeave={startSlideTimer}
            variants={sliderVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            alt=""
          />
        </div>
        <div className="arrows">
          <div onClick={prev}>
            <span>
              <FaArrowAltCircleLeft></FaArrowAltCircleLeft>
            </span>
          </div>
          <div onClick={next}>
            <span>
              <FaArrowAltCircleRight></FaArrowAltCircleRight>
            </span>
          </div>
        </div>
        <div className="dot">
          {sliderData.map((_, index) => (
            <button
              key={index}
              className={`carousel-indicator-item${
                currentSlide === index ? " active" : ""
              }`}
              onClick={() => switchIndex(index)}
            ></button>
          ))}
        </div>
      </div>
    </>
  );
};

export default Slider;
