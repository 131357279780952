import React from "react";
import "./Message.css";
import director_image from "../../../assets/ekramul.jpg";
import QacTeam from "../../qac-team/QacTeam";

const DirectorMessage = () => {
  return (
    <>
      <div className="message-details-body">
        <div className="details-page-sub_heading">
          <h4>Message from Director</h4>
        </div>
        <div className="message-details">
          <div className="message-details-image">
            <img src={director_image} alt="Professor-dr-abdul-awal-khan"></img>
          </div>
          <div>
            <p>
              Institutional Quality Assurance Cell (IQAC) at International
              Standard University (ISU) began its journey on 4 February, 2020
              with a view to ensuring quality at all levels of education and
              research in terms of governance, academic standard, curriculum,
              assessment, development, research, and continual improvement of
              the university. The main objective of the activities of the IQAC
              at ISU is to work for the same under the guidance of the Quality
              Assurance Unit of the UGC, BNQF and the BAC in consultation with
              the Quality Assurance Committee (QAC) of ISU. <br />
              <br />
              Since its inception, IQAC has been working relentlessly to promote
              quality at all areas of teaching and research at ISU under the
              earnest guidance and supports of the founder Vice Chancellor of
              ISU Prof. Dr. Abdul Awal Khan, and the Chairman of Quality
              Assurance Committee (QAC), ISU. The founder Chairman and the Vice
              Chairman of the ISU Trustee Board Engr. A.K.M. Mosharraf Hussain
              and Engr. Md. Atiqur Rahman respectively also posit utmost
              priority on quality education and research at ISU. IQAC at ISU has
              been working hard to promote a quality culture and learning
              environment in the university to facilitate academic staff to
              provide quality education to the students and thereby ensuring
              their employability including self-employment and meeting their
              social responsibility. With the active support and sincere
              guidance of the IQAC all the program offering departments of ISU
              have successfully designed and implemented Outcome Based Education
              (OBE) curriculum and student assessment. <br /> <br />
              In order to ensure quality education at ISU, series of workshops
              and training programs have been arranged on a continuous basis
              focusing mainly on pedagogy, designing OBE based course outline,
              preparing lesson plan and course files, setting question paper and
              selecting assessment techniques. As quality management is an
              organization wide continuous effort, in addition to the academic
              staff, training programs for the administrative staff including
              rank and files have also been arranged continually for their
              professional development. This webpage has been developed to
              document and share our development through furnishing information
              on IQAC activities including workshops, training, seminars,
              research works, publications etc. We are pleased to update the
              webpage regularly with the latest improvements in the Cell. <br />
              <br />
              We solicit and appreciate suggestions and queries from our valued
              stakeholders to achieve the objectives and goals of IQAC for
              enhancing quality education at ISU.
            </p>
            <p>
              <hr />
              <br />
              <span style={{ fontWeight: "bold", fontSize: "1.1rem" }}>
                Professor Dr. Md. Ekramul Hoque
              </span>
              <br />
              <span style={{ fontWeight: "500", fontSize: "0.9rem" }}>
                IQAC Director & Professor, Department of Business Administration
              </span>
            </p>
          </div>
        </div>
      </div>
      <QacTeam></QacTeam>
    </>
  );
};

export default DirectorMessage;
