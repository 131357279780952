import React from 'react';
import './Objectives.css';
import QacTeam from '../../qac-team/QacTeam';
import './Objectives.css'

const Objectives = () => {
  return (
    <>
        <div className='objectives-body'>
            <div className='details-page-sub_heading'>
            <h4>Objectives</h4>
            </div>
            <div className='objectives-content'>
              <ul>
                  <li>To develop a system for conscious, consistent and catalytic action to improve the academic and administrative performance of the universities.</li>
                  <li>To undertake measures for quality enhancement and excellence in teaching-learning through internationalization as well as to institutionalize the best practices of QA activities.</li>
                </ul>
            </div>
        </div>
        <QacTeam></QacTeam>
    </>
  )
}

export default Objectives