import React from 'react'
import QacTeam from '../qac-team/QacTeam'
import './document.css';
import {OthersData} from '../../data/guidelineData'
const Others = () => {
  return (
    <>
        <div className='team-body'>
            <div className='details-page-sub_heading'>
              <h4>Others</h4>
            </div>
            <div className='team-content'>
                <div className='qac-table'>
                  <table>
                    <tr>
                      <th width="5%">No</th>
                      <th width="35%">Item</th>
                      <th width="50%">Description</th>
                      <th width="10%">Download</th>
                    </tr>
                    {
                      OthersData.map((manual, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{manual.heading}</td>
                          <td>{manual.description}</td>
                          <td><a href={manual.file} download>PDF Download</a></td>
                        </tr>
                      ))
                    }
                  </table>
                </div>
            </div>
        </div>
        <QacTeam></QacTeam>
    </>
  )
}

export default Others