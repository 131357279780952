export const MenuData = [
  {
    id: 1,
    path: "/",
    name: "Home",
    cName: "",
  },
  {
    id: 2,
    path: "",
    name: "About US",
    subMenu: [
      {
        id: 1,
        parentId: 2,
        name: "Vision & Mission",
        path: "/vision-mission",
      },
      {
        id: 2,
        parentId: 2,
        name: "Objectives",
        path: "/objectives",
      },
      {
        id: 3,
        parentId: 2,
        name: "Message from the Vice Chancellor and QAC Chairman",
        path: "/message-from-vice-chancellor-and-qac-chairman",
      },
      {
        id: 4,
        parentId: 2,
        name: "Message from Director",
        path: "/message-from-director",
      },
      // {
      //   id: 5,
      //   parentId: 2,
      //   name: "Additional Director Message",
      //   path: "/message-from-additional-director",
      // },
    ],
  },
  {
    id: 3,
    name: "Committee",
    path: "",
    subMenu: [
      // {
      //   id: 1,
      //   parentId: 3,
      //   name: "Organogram",
      //   path: "/organogram",
      // },
      {
        id: 2,
        parentId: 3,
        name: "QAC",
        path: "/qac",
      },

      {
        id: 3,
        parentId: 3,
        name: "FQAC",
        path: "/fqac",
      },
      {
        id: 4,
        parentId: 3,
        name: "PSAC",
        path: "/psac",
      },
    ],
  },
  {
    id: 4,
    name: "News & Events",
    path: "/news-and-events",
  },
  {
    id: 5,
    name: "Documents",
    path: "",
    subMenu: [
      {
        id: 1,
        parentId: 5,
        name: "Guideline / Manual",
        path: "/guideline",
      },
      // {
      //     id: 2,
      //     parentId: 5,
      //     name:'Reports',
      //     path:'/reports'
      // },
      // {
      //     id: 3,
      //     parentId: 5,
      //     name:'Self Assessment Survey',
      //     path:'/self-assessment-survey'
      // },
      {
        id: 4,
        parentId: 5,
        name: "Others",
        path: "/others",
      },
    ],
  },
  {
    id: 6,
    name: "Training Workshop / Seminar",
    path: "/workshop",
  },
  {
    id: 7,
    name: "Gallery",
    path: "/gallery",
  },
];
