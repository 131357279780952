import React from 'react'

const Reports = () => {
  return (
    <>
        <h1>Reports Page</h1>
    </>
  )
}

export default Reports