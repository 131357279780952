import React, { useEffect, useState } from "react";
import "./Event.css";
import { BsChevronDoubleRight, BsChevronDoubleLeft } from "react-icons/bs";
import { motion } from "framer-motion";
import axios from "axios";
import { Link } from "react-router-dom";

const strokeMotionVariants = {
  hidden: {
    opacity: 0,
    width: 0,
  },
  visible: {
    opacity: 1,
    width: "5rem",
    transition: {
      type: "tween",
      duration: 1.5,
      ease: "easeInOut",
    },
  },
};

const headingVariants = {
  hidden: {
    opacity: 0,
    x: "-100vw",
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      type: "tween",
      delay: 1,
      duration: 1.5,
      ease: "easeInOut",
    },
  },
};

const eventContainerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      type: "tween",
      delay: 0.2,
      duration: 1,
      ease: "easeInOut",
    },
  },
};

const eventImageCOntainerVariants = {
  hidden: {
    opacity: 0,
    x: "-100px",
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      type: "tween",
      duration: 1.5,
      delay: 1,
      ease: "easeInOut",
    },
  },
};

const eventBodyVariants = {
  hidden: {
    opacity: 0,
    x: "100px",
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      type: "tween",
      duration: 1.5,
      delay: 1,
      ease: "easeInOut",
    },
  },
};

const readMoreVariants = {
  hidden: {
    opacity: 0,
    y: "50px",
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "tween",
      duration: 1.5,
      delay: 1.5,
      ease: "easeInOut",
    },
  },
};

const Event = () => {
  const [eventData, setEventData] = useState(0);
  const [newsEvents, setNewsEvents] = useState([0]);

  useEffect(() => {
    axios
      .get("https://www.isu.ac.bd/api/events")
      .then((res) => setNewsEvents(res.data))
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const sDataLenght = newsEvents.length;
  const prev = () => {
    const index = eventData > 0 ? eventData - 1 : sDataLenght - 1;
    setEventData(index);
  };
  const next = () => {
    const index = eventData < sDataLenght - 1 ? eventData + 1 : 0;
    setEventData(index);
  };
  console.log(newsEvents);
  return (
    <>
      <motion.div
        className="event-container"
        variants={eventContainerVariants}
        initial="hidden"
        whileInView="visible"
      >
        <div className="event-body">
          <div className="event-h">
            <motion.div
              className="title_stroke"
              variants={strokeMotionVariants}
            ></motion.div>
            <motion.span className="heading" variants={headingVariants}>
              News & Events
            </motion.span>
          </div>

          <div className="event-content">
            <motion.div variants={eventImageCOntainerVariants}>
              <motion.img
                key={eventData}
                initial={{ opacity: 0, x: -100 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 100 }}
                transition={{ duration: 1.5, type: "tween", ease: "easeInOut" }}
                src={newsEvents[eventData].image}
                alt=""
              />
            </motion.div>
            <motion.div
              className="event-short-details"
              variants={eventBodyVariants}
            >
              <motion.div
                key={eventData}
                initial={{ opacity: 0, x: 100 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -100 }}
                transition={{ duration: 1.5, type: "tween", ease: "easeInOut" }}
              >
                <p>{newsEvents[eventData].heading}</p>

                <span>
                  <Link
                    to={{
                      pathname: `/news-events/${newsEvents[eventData].news_id}`,
                    }}
                    key={newsEvents[eventData]}
                    className="read_more"
                  >
                    Read More...
                  </Link>
                </span>
              </motion.div>
            </motion.div>
          </div>
        </div>
        <motion.div className="arrow-e" variants={readMoreVariants}>
          <span onClick={() => prev()}>
            <BsChevronDoubleLeft></BsChevronDoubleLeft>
          </span>
          <span onClick={() => next()}>
            {" "}
            <BsChevronDoubleRight></BsChevronDoubleRight>{" "}
          </span>
        </motion.div>
      </motion.div>
    </>
  );
};

export default Event;
