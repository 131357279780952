import React from "react";
import "./MissionAndVission.css";
import QacTeam from "../../qac-team/QacTeam";

const MissionAndVisionDetails = () => {
  return (
    <>
      <div className="mission-vision-details-body">
        <div className="details-page-sub_heading">
          <h4>Vision & Mission</h4>
        </div>
        <div className="mission-vision-details-content">
          <div className="mission-vision-d">
            <h3>Vision</h3>
            <p>
              Achieving excellence in quality education through continuous
              improvement of teaching, learning, research and holistic support
              services to make ISU an eminent learning center of international
              standard.{" "}
            </p>
          </div>
          <div className="mission-vision-d">
            <h3>Mission</h3>
            <p>
              IQAC at ISU is committed to create an enabling environment and a
              quality culture in all areas of teaching and learning related to
              tertiary level education in terms of governance, academic
              standard, curriculum, assessment, development, research, and
              continual improvement to meet the needs of the stakeholders
              including teachers, students, alumni, employers, and the society
              at large.
            </p>
          </div>
        </div>
      </div>
      <QacTeam></QacTeam>
    </>
  );
};

export default MissionAndVisionDetails;
