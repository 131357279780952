import React from "react";
import "./Welcome.css";
const Welcome = () => {
  return (
    <>
      <div className="welcome_message_body">
        <div>
          <h3>Welcome to IQAC</h3>
          <p>
            ISU formed its Institutional Quality Assurance Cell (IQAC) on 04
            February 2020, with a view to ensuring quality in terms of the
            governance, academic standard, OBE curriculum, assessment,
            development, research, and continual improvement of the university.
            The main objective of the activities of the IQAC at ISU is to work
            for the same in under the guidance of the UGC and the BAC, along
            with the instruction of the Vice Chancellor of ISU. As ISU aims at
            achieving accreditation from BAC, by fulfilling the required
            criteria set by the UGC and the BAC, here at the university IQAC is
            arranging different workshops, and training sessions.
          </p>
        </div>
      </div>
    </>
  );
};

export default Welcome;
