import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./App.css";
import AdditionalDirectorMessage from "./Components/AboutUs/Message/AdditionalDirectorMessage";
import ChairmanMessage from "./Components/AboutUs/Message/ChairmanMessage";
import DirectorMessage from "./Components/AboutUs/Message/DirectorMessage";
import Objectives from "./Components/AboutUs/Objectives/Objectives";
import Contact from "./Components/ContactUs/Contact";
import Manual from "./Components/Documents/Manual";
import Others from "./Components/Documents/Others";
import Reports from "./Components/Documents/Reports";
import SelfAssesmentSurvey from "./Components/Documents/SelfAssesmentSurvey";
import Error from "./Components/Error";
import Gallery from "./Components/Gallery/Gallery";
import Home from "./Components/Home/Home";
import FQAC from "./Components/IQACTeam/FQAC";
import IQAC from "./Components/IQACTeam/IQAC";
import Officials from "./Components/IQACTeam/Officials";
import Organogram from "./Components/IQACTeam/Organogram";
import PSAC from "./Components/IQACTeam/PSAC";
import QAC from "./Components/IQACTeam/QAC";
import MainBody from "./Components/MainBody";
import NewsAndEvents from "./Components/News&Events/NewsAndEvents";
import WorkShop from "./Components/WorkShop/WorkShop";
import MessageDetails from "./Components/Message/MessageDetails";
import MissionAndVisionDetails from "./Components/AboutUs/MissionAndVision/MissionVissionDetails";
import ActivityDetails from "./Components/activities/ActivityDetails";
import Images from "./Components/Gallery/Images";
function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainBody></MainBody>}>
            <Route index element={<Home></Home>}></Route>
            <Route path="/gallery" element={<Gallery></Gallery>}></Route>
            <Route path="/contact-us" element={<Contact></Contact>}></Route>
            <Route
              path="/news-and-events"
              element={<NewsAndEvents></NewsAndEvents>}
            ></Route>
            <Route path="/workshop" element={<WorkShop></WorkShop>}></Route>
            <Route
              path="/vision-mission"
              element={<MissionAndVisionDetails></MissionAndVisionDetails>}
            ></Route>
            <Route
              path="/objectives"
              element={<Objectives></Objectives>}
            ></Route>
            <Route
              path="/message-from-vc-and-qac-chairman"
              element={<ChairmanMessage></ChairmanMessage>}
            ></Route>
            <Route
              path="/message-from-director"
              element={<DirectorMessage></DirectorMessage>}
            ></Route>
            <Route
              path="/message-from-additional-director"
              element={<AdditionalDirectorMessage></AdditionalDirectorMessage>}
            ></Route>
            <Route
              path="/organogram"
              element={<Organogram></Organogram>}
            ></Route>
            <Route path="/qac" element={<QAC></QAC>}></Route>
            <Route path="/iqac" element={<IQAC></IQAC>}></Route>
            <Route path="/fqac" element={<FQAC></FQAC>}></Route>
            <Route path="/psac" element={<PSAC></PSAC>}></Route>
            <Route path="/officials" element={<Officials></Officials>}></Route>
            <Route path="/guideline" element={<Manual></Manual>}></Route>
            <Route path="/reports" element={<Reports></Reports>}></Route>
            <Route
              path="/self-assessment-survey"
              element={<SelfAssesmentSurvey></SelfAssesmentSurvey>}
            ></Route>
            <Route path="/others" element={<Others></Others>}></Route>
            <Route
              path="/message-from-vice-chancellor-and-qac-chairman"
              element={<MessageDetails></MessageDetails>}
            ></Route>
            <Route
              path="/news-events/:eventId"
              element={<ActivityDetails></ActivityDetails>}
            ></Route>
            <Route
              path="/gallery/images/:gallery_id"
              element={<Images></Images>}
            ></Route>
            <Route path="/*" element={<Error></Error>}></Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}
export default App;
