import vc_image from '../../assets/professor-dr-abdul-awal-khan.png';
import React from 'react'
import './Message.css';
import QacTeam from '../qac-team/QacTeam';

const MessageDetails = () => {
  return (
    <>
       <div className='message-details-body'>
        <div className='details-page-sub_heading'>
          <h4>Message from Vice-Chancellor & QAC Chair</h4>
        </div>
        <div className='message-details'>
              <div className='message-details-image'><img src={vc_image} alt='Professor-dr-abdul-awal-khan'></img></div>
              <div>
                  <p> 
                      The opportunity of writing this message for IQAC of International Standard University (ISU) is, indeed, a matter of great pleasure and satisfaction to me. Institutional Quality Assurance Cell (IQAC) of International Standard University (ISU) has been established with the goal of ensuring quality education for the students of this university that will bring positive change in their lives in terms of knowledge, skill and attitude. IQAC is moving towards its goal with the support from the university management, teachers, students, officials, alumni and the society at large. I would like to express my heartfelt gratitude to ISU, UGC, BAC, and Government of the People’s Republic of Bangladesh for their constant guidance as well as supports in running IQAC at ISU. Moreover, IQAC at ISU is committed to create an enabling environment and quality culture in the campus. Quality assurance is a continuous process. We will continue to identify the scope of further improvement with the feedback from all stakeholders, and act accordingly to build a better future of our students as well as contribute to the socio-economic development of Bangladesh. I expect the heartiest cooperation 
                      of all the stakeholders of ISU to achieve the strategic objectives and goals of IQAC for enhancing the quality of education in near future.
                  </p>
                  <p>
                      <hr />
                      <br />
                      <span style={{ fontWeight: 'bold', fontSize:'1.1rem' }}>Prof. Dr. Abdul Awal Khan</span>
                      <br />
                      <span style={{ fontWeight:'500', fontSize: '0.9rem' }}>QAC Chair & Vice Chancellor, ISU</span>
                  </p>
              </div>
        </div>
       </div>
       <QacTeam></QacTeam>
    </>
  )
}

export default MessageDetails