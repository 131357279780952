import React from 'react';
import { FaSistrix } from "react-icons/fa";
import {RiMenu3Line, RiCloseLine} from 'react-icons/ri';
import './Header.css';
import {MenuData} from '../../data/MenuData';
import Menu from '../Menu/Menu';
import IQACLogo from '../../assets/iqac_logo.png';
import ISULogo from '../../assets/isu_logo.png';
import { useState } from 'react';
import MenuMobile from '../Menu/MenuMobile';


const Header = () => {   
  const [toggleMenu, setToggleMenu] = useState(false);
  return (
    <>
        <div className="header">
          <div className="menu-header">
            <div className="menu">
                <ul className='menu-list'>
                  {
                    MenuData.map((menuItem, index) => {
                      return (
                        <Menu items={menuItem} key={index}></Menu>
                      )
                    })
                  }
                </ul>
            </div>
            <div className="search-icon">
              <FaSistrix></FaSistrix>
            </div>
          </div>
          <div className='iqac__navbar-mobile-menu'>
            <div className="mobile-view">
                <div className="mobile-view-left-th">
                    <img src={IQACLogo} alt="" width={200} height={1000} />
                    <img src={ISULogo} alt="" />
                </div>
            </div>
            <div className="iqac__navbar-mobile-menu-item">
              {
                  toggleMenu ? <RiCloseLine color='#808080' size={26} onClick={() => setToggleMenu(false)} />
                  : <RiMenu3Line  color='#808080' size={26} onClick={() => setToggleMenu(true)} />
                }
                {
                  toggleMenu && (
                    <div className='iqac__navbar-mobile-menu_container'>
                      <div className='iqac__navbar-mobile-menu_container'>
                      {
                        MenuData.map((menuItem, index) => {
                          return (
                            <MenuMobile items={menuItem} key={index}></MenuMobile>
                          )
                        })
                      }
                      </div>
                    </div>
                  )
                }
            </div>
            </div>
        </div>
    </>
  )
}

export default Header