import React, { useState } from 'react';
import SubMenu from './SubMenu';
import './Menu.css';
import {NavLink} from 'react-router-dom';


const Menu = ({items}) => {
    const [dropdown, setDropdown] = useState(false);
  return (
    <>
        
        <li className='menu-items' onMouseEnter={()=>setDropdown(true)} onMouseLeave={()=> setDropdown(false)}>
            {
                items.subMenu ? (
                    <>
                        <button className='menu-button' type="button" aria-haspopup="menu" aria-expanded={dropdown ? "true" : "false"}>
                        {items.name}{" "}
                        </button>
                        <SubMenu submenus={items.subMenu} dropdown={dropdown}></SubMenu>
                    </>
                    ) : (
                        <NavLink to={items.path}>{items.name}</NavLink>
                    )
            }
        </li>
    </>
  )
}

export default Menu