import React from 'react'
import './IQACTeam.css'

const IQAC = () => {
  return (
    <>
        <h1>IQAC</h1>
    </>
  )
}

export default IQAC