import React, { useEffect, useState } from "react";
import "./WorkShop.css";

import { Link } from "react-router-dom";
import axios from "axios";
import QacTeam from "../qac-team/QacTeam";

const Activity = () => {
  const [newsEvents, setNewsEvents] = useState([0]);
  useEffect(() => {
    axios
      .get("https://www.isu.ac.bd/api/events")
      .then((res) => setNewsEvents(res.data))
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <div className="news-events-body">
        <div className="details-page-sub_heading">
          <h4>Training Workshop/Seminar</h4>
        </div>
        <div className="news-events-content">
          {newsEvents.map((item, index) => (
            <Link
              to={{
                pathname: `/news-events/${item.news_id}`,
              }}
              key={index}
            >
              <div class="responsive">
                <div className="gallery">
                  <a target="_blank" href="img_5terre.jpg">
                    <img src={item.image} alt="" width="300" height="200" />
                  </a>
                  <div class="desc">{item.heading}</div>
                  <div className="date_time"></div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
      <QacTeam></QacTeam>
    </>
  );
};

export default Activity;
