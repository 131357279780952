import React from 'react'
import './IQACTeam.css'

const Officials = () => {
  return (
    <>
        <h1>Staff / Officials</h1>
    </>
  )
}

export default Officials