import React from 'react'

const SelfAssesmentSurvey = () => {
  return (
    <>
        <h1>Self Assessment Survey</h1>
    </>
  )
}

export default SelfAssesmentSurvey