import file1 from '../assets/documents/manual/BAC_Accreditation-Manual_June-2022(4).pdf'
import file2 from '../assets/documents/manual/Bangladesh-National-Qualification-Framework-January-2021.pdf'
import file3 from '../assets/documents/manual/BNQF-Full.pdf'
import file4 from '../assets/documents/manual/IQAC-Operations-Manual_2nd-ed_2015.pdf'
import file5 from '../assets/documents/manual/Program_Self-Assessment-Manual_2nd-ed_2016.pdf'
import others1 from '../assets/documents/others/IQAC-Organogram_Oct-2021(1).pdf'
import others2 from '../assets/documents/others/Approved_Accreditation-Standards-and-Criteria(1).pdf'
import others3 from '../assets/documents/others/OBE-Curriculum-Template.pdf'

export const ManualData = [
    {
        id: 1,
        heading: 'BNQF Full Version ',
        file: file3,
        description:"Bangladesh National Qualification Framework for all educational levels",
        status : 1
    },
    {
        id: 2,
        heading: 'Accreditation Manual 2022',
        file: file1,
        description:"The manual provided by the Bangladesh Accreditation Council (BAC)",
        status : 1
    },
    {
        id: 3,
        heading: 'National Qualification Framework',
        file: file2,
        description:"Bangladesh National Qualification Framework revised and accepted in January 2021",
        status : 1
    },
    {
        id: 4,
        heading: 'IQAC Operations Manual',
        file: file4,
        description:"Second version of the IQAC Operations Manual prepared by the UGC",
        status : 1
    },
    {
        id: 5,
        heading: 'Program Self-Assessment Manual',
        file: file5,
        description:"Second version of the Program Self-Assessment Manual prepared by the UGC",
        status : 1
    },
   
  ];

  export const OthersData = [
    {
        id: 1,
        heading: 'IQAC Organogram',
        file: others1,
        description:"The latest organogram of IQAC with job descriptions provided by the UGC on October 2021",
        status : 1
    },
    {
        id: 2,
        heading: 'BAC Standards and Criteria',
        file: others2,
        description:"Approved version  of the Bangladesh Accreditation Council (BAC) Standards and Criteria needed for program accreditation",
        status : 1
    },
    {
        id: 3,
        heading: 'OBE Curriculum Template',
        file: others3,
        description:"approved version of the OBE Curriculum Template for all university programs prepared by the UGC",
        status : 1
    }
  ];