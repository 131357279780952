import React from 'react';
import Footer from '../../Footer/Footer';
import Header from '../../Header/Header';
import TopHeader from '../../TopHeader/TopHeader';
import './Message.css';

const AdditionalDirectorMessage = () => {
  return (
    <>
        <h1>Additional Director Message</h1>
    </>
  )
}

export default AdditionalDirectorMessage