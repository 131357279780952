import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Lightbox from "yet-another-react-lightbox";
import {
  Download,
  Fullscreen,
  Thumbnails,
  Zoom,
} from "yet-another-react-lightbox/plugins";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import ImageGallery from "./ImageGallery";

const Images = () => {
  let { gallery_id } = useParams();

  const [galleryImages, setGalleryImages] = useState([0]);
  const [index, setIndex] = useState(-1);

  useEffect(() => {
    axios
      .get(`https://www.isu.ac.bd/api/gallery/${gallery_id}`)
      .then((res) => setGalleryImages(res.data))
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <div className="gallery-content">
        <ImageGallery
          data={galleryImages}
          onClick={(currentIndex) => setIndex(currentIndex)}
        />
        <Lightbox
          plugins={[Download, Zoom, Fullscreen, Thumbnails]}
          index={index}
          open={index >= 0}
          close={() => setIndex(-1)}
          slides={galleryImages}
        />
      </div>
    </>
  );
};

export default Images;
