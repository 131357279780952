import React from 'react';
import IQACLogo from '../../assets/iqac_logo.png';
import ISULogo from '../../assets/isu_logo.png';
import './TopHeader.css';

const TopHeader = ({FaAlignLeft}) => {
  return (
    <>
        <div className="header-top">
            <div className="left-th">
                <img src={IQACLogo} alt="iqac-logo"/>
            </div>
            <div className="right-th">
                <img src={ISULogo} alt="" />
            </div>
        </div>
    </>
  )
}

export default TopHeader