import React, { useEffect, useState } from "react";
import "./Gallery.css";

import { Link } from "react-router-dom";
import axios from "axios";

const Gallery = () => {
  const [newsEvents, setNewsEvents] = useState([0]);
  useEffect(() => {
    axios
      .get("https://www.isu.ac.bd/api/gallery")
      .then((res) => setNewsEvents(res.data))
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <div className="gallery-body">
        <div className="container">
          <div className="details-page-sub_heading">
            <h4>Gallery</h4>
          </div>
          <div className="gallery-content">
            {newsEvents.map((item, index) => (
              <Link
                to={{
                  pathname: `/gallery/images/${item.publish_news_id}`,
                }}
                key={index}
              >
                <div class="gallery_responsive">
                  <div className="gallery_item">
                    <a target="_blank" href="img_5terre.jpg">
                      <img src={item.cover_image} alt="img" />
                    </a>
                    <p className="item_heading">{item.heading}</p>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Gallery;
