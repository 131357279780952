import React from "react";
import "./IQACTeam.css";
import QacTeam from "../qac-team/QacTeam";
import vc from "../../assets/professor-dr-abdul-awal-khan.png";
import member1 from "../../assets/monjur_morshed.jpg";
import member4 from "../../assets/syed-mustafizur-rahman-chowdhury.jpg";
import member5 from "../../assets/Dr. Md. Abul Kalam Azad.jpg";
import member9 from "../../assets/engr-md-abdul-based-miah.png";
import member10 from "../../assets/Dr. Muhammad Kamruzzaman.jpg";

const FQAC = () => {
  return (
    <>
      <div className="team-body">
        <div className="details-page-sub_heading">
          <h4>QAC Committee</h4>
        </div>
        <div className="team-content">
          <div className="team-heading">
            <h4>Faculty Quality Assurance Committee (FQAC)</h4>
          </div>
          <hr />
          <div className="qac-table">
            <div className="table-content">
              <h4>FBS: Faculty of Business Studies</h4>
            </div>
            <table>
              <tr>
                <th width="5%">No</th>
                <th width="35%">Name</th>
                <th width="35%">Designation</th>
                <th width="15%">Position</th>
                <th width="10%">Image</th>
              </tr>
              <tr>
                <td>1</td>
                <td>Prof. Dr. Monjur Morshed Mahmud</td>
                <td>Dean, Faculty of Business Studies</td>
                <td>Chair</td>
                <td>
                  <img src={member1} alt="qac" width={100} height={100} />
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td>Professor Dr. Abdul Awal Khan</td>
                <td>
                  Vice-Chancellor & Dean, Faculty of Engineering & Technology
                </td>
                <td>Member</td>
                <td>
                  <img src={vc} alt="qac" width={100} height={100} />
                </td>
              </tr>
              <tr>
                <td>3</td>
                <td>Dr. Md. Abul Kalam Azad</td>
                <td>
                  Dean (In-Charge), Faculty of Humanities and Social Sciences
                </td>
                <td>Member</td>
                <td>
                  <img src={member5} alt="qac" width={100} height={100} />
                </td>
              </tr>
              <tr>
                <td>4</td>
                <td>Dr. Muhammad Kamruzzaman</td>
                <td>Chairperson, Department of Business Administration</td>
                <td>Member</td>
                <td>
                  <img src={member10} alt="qac" width={100} height={100} />
                </td>
              </tr>
            </table>
          </div>
          <div className="qac-table">
            <div className="table-content">
              <h4>FET: Faculty of Engineering and Technology</h4>
            </div>
            <table>
              <tr>
                <th width="5%">No</th>
                <th width="35%">Name</th>
                <th width="35%">Designation</th>
                <th width="15%">Position</th>
                <th width="10%">Image</th>
              </tr>
              <tr>
                <td>1</td>
                <td>Professor Dr. Abdul Awal Khan</td>
                <td>
                  Vice-Chancellor & Dean, Faculty of Engineering & Technology
                </td>
                <td>Chair</td>
                <td>
                  <img src={vc} alt="qac" width={100} height={100} />
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td>Prof. Dr. Monjur Morshed Mahmud</td>
                <td>Dean, Faculty of Business Studies</td>
                <td>Member</td>
                <td>
                  <img src={member1} alt="qac" width={100} height={100} />
                </td>
              </tr>
              <tr>
                <td>3</td>
                <td>Dr. Md. Abul Kalam Azad</td>
                <td>
                  Dean (In-Charge), Faculty of Humanities and Social Sciences
                </td>
                <td>Member</td>
                <td>
                  <img src={member5} alt="qac" width={100} height={100} />
                </td>
              </tr>
              <tr>
                <td>4</td>
                <td>Prof. Engr. Md. Abdul Based Miah</td>
                <td>Chairperson, Department of Textile Engineering</td>
                <td>Member</td>
                <td>
                  <img src={member9} alt="qac" width={100} height={100} />
                </td>
              </tr>
              <tr>
                <td>5</td>
                <td>Syed Mustafizur Rahman Chowdhury</td>
                <td>
                  Chairperson, Department of Computer Science & Engineering
                </td>
                <td>Member</td>
                <td>
                  <img src={member4} alt="qac" width={100} height={100} />
                </td>
              </tr>
            </table>
          </div>
          <div className="qac-table">
            <div className="table-content">
              <h4>FHSS: Faculty of Humanities & Social Science</h4>
            </div>
            <table>
              <tr>
                <th width="5%">No</th>
                <th width="35%">Name</th>
                <th width="35%">Designation</th>
                <th width="15%">Position</th>
                <th width="10%">Image</th>
              </tr>
              <tr>
                <td>1</td>
                <td>Dr. Md. Abul Kalam Azad</td>
                <td>
                  Dean (In-Charge), Faculty of Humanities and Social Sciences
                </td>
                <td>Member</td>
                <td>
                  <img src={member5} alt="qac" width={100} height={100} />
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td>Professor Dr. Abdul Awal Khan</td>
                <td>
                  Vice-Chancellor & Dean, Faculty of Engineering & Technology
                </td>
                <td>Member</td>
                <td>
                  <img src={vc} alt="qac" width={100} height={100} />
                </td>
              </tr>
              <tr>
                <td>3</td>
                <td>Prof. Dr. Monjur Morshed Mahmud</td>
                <td>Dean, Faculty of Business Studies</td>
                <td>Member</td>
                <td>
                  <img src={member1} alt="qac" width={100} height={100} />
                </td>
              </tr>
              <tr>
                <td>4</td>
                <td>Dr. Md. Abul Kalam Azad</td>
                <td>Chairperson, Department of English</td>
                <td>Member</td>
                <td>
                  <img src={member5} alt="qac" width={100} height={100} />
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <QacTeam></QacTeam>
    </>
  );
};

export default FQAC;
