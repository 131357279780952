import React from 'react'
import './Contact.css';

const Contact = () => {
  return (
    <>
        <h1>Contact Us</h1>
    </>
  )
}

export default Contact