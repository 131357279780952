import React from "react";
import "./ImageGallery.css";

const ImageGallery = (props) => {
  const { data, onClick } = props;

  const handleOnClick = (index) => {
    onClick(index);
  };
  return (
    <>
      <div className="image-container">
        {data.map((slide, index) => (
          <div
            key={index}
            className="image"
            onClick={() => handleOnClick(index)}
          >
            <img src={slide.src} alt={index} />
          </div>
        ))}
      </div>
    </>
  );
};

export default ImageGallery;
