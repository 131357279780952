import React from "react";
import "./Message.css";
import VC from "../../assets/professor-dr-abdul-awal-khan.png";
import Director from "../../assets/ekramul.jpg";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";

const strokeMotionVariants = {
  hidden: {
    opacity: 0,
    width: 0,
  },
  visible: {
    opacity: 1,
    width: "5rem",
    transition: {
      type: "tween",
      duration: 0.6,
      ease: "easeInOut",
    },
  },
};

const headingVariants = {
  hidden: {
    opacity: 0,
    x: "-30px",
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      type: "tween",
      delay: 0.5,
      duration: 0.5,
      ease: "easeInOut",
    },
  },
};

const vcMessageVariants = {
  hidden: {
    opacity: 0,
    y: "-30px",
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "tween",
      duration: 0.4,
      delay: 0.4,
      ease: "easeInOut",
    },
  },
};

const directorMessageVariants = {
  hidden: {
    opacity: 0,
    y: "30px",
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "tween",
      duration: 0.4,
      delay: 0.4,
      ease: "easeInOut",
    },
  },
};

const Message = () => {
  return (
    <>
      <div className="message-container">
        <div className="heading">
          <motion.div
            className="title_stroke"
            variants={strokeMotionVariants}
            initial="hidden"
            whileInView="visible"
          ></motion.div>
          <motion.span
            className="heading"
            variants={headingVariants}
            initial="hidden"
            whileInView="visible"
          >
            Messages
          </motion.span>
        </div>
        <div className="message-body">
          <motion.div
            className="message-content"
            variants={vcMessageVariants}
            initial="hidden"
            whileInView="visible"
          >
            <div className="message-image">
              <img src={VC} alt="" />
            </div>
            <div className="message">
              <span className="sub_heading">
                Message from the Vice Chancellor and QAC Chair
              </span>
              <span>
                The opportunity of writing this message for IQAC of
                International Standard University (ISU) is, indeed, a matter of
                great pleasure and satisfaction to me. Institutional Quality
                Assurance Cell (IQAC){" "}
              </span>
              <NavLink
                style={{ textDecoration: "none" }}
                to="/message-from-vice-chancellor-and-qac-chairman"
              >
                <span className="read_more">Read More..</span>
              </NavLink>
            </div>
          </motion.div>
          <motion.div
            className="message-content"
            variants={directorMessageVariants}
            initial="hidden"
            whileInView="visible"
          >
            <div className="message-image">
              <img src={Director} alt="" />
            </div>
            <div className="message">
              <span className="sub_heading">Message from the Director</span>
              <span>
                Institutional Quality Assurance Cell (IQAC) at International
                Standard University (ISU) began its journey on 4 February, 2020
                with a view to ensuring quality at all levels of education and
                research in terms of governance, academic standard
              </span>
              <NavLink
                style={{ textDecoration: "none" }}
                to="/message-from-director"
              >
                <span className="read_more">Read More..</span>
              </NavLink>
            </div>
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default Message;
