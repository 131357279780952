import React, { useEffect, useState } from "react";
import QacTeam from "../qac-team/QacTeam";
import { useParams } from "react-router-dom";

import axios from "axios";

const ActivityDetails = () => {
  let { eventId } = useParams();
  const idParams = eventId;

  const [newsEvents, setNewsEvents] = useState([0]);

  useEffect(() => {
    axios
      .get("https://www.isu.ac.bd/api/events")
      .then((res) => setNewsEvents(res.data))
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <div>
        {newsEvents.map((item, index) => {
          if (item.news_id == idParams) {
            return (
              <div className="event-details" key={index}>
                <div className="container">
                  <div className="event_content_top">
                    <div className="details-page-sub_heading">
                      <h4>{item.heading}</h4>
                    </div>
                    <div className="event-thumbnail">
                      <img src={item.image} alt="event-thumbnail" />
                    </div>
                  </div>
                  <div className="event-content-details">
                    <p>{item.details}</p>
                  </div>
                </div>
              </div>
            );
          } else {
            <h2>NO Data Found</h2>;
          }
        })}
      </div>
      <QacTeam></QacTeam>
    </>
  );
};

export default ActivityDetails;
