import React from "react";
import "./IQACTeam.css";
import QacTeam from "../qac-team/QacTeam";
import vc from "../../assets/professor-dr-abdul-awal-khan.png";
import member1 from "../../assets/Dr. Md. Abul Kalam Azad.jpg";
import member2 from "../../assets/ekramul.jpg";
import member3 from "../../assets/Dr.Khandoker-Saif-Uddin-1.jpg";
import member4 from "../../assets/syed-mustafizur-rahman-chowdhury.jpg";
import member5 from "../../assets/Dr. Muhammad Kamruzzaman.jpg";
import member6 from "../../assets/faizullah-kawshik.jpg";
import member7 from "../../assets/ataur-rahman-bhuiyan.jpg";
import member8 from "../../assets/masum.png";
import member9 from "../../assets/monjur_morshed.jpg";

const QAC = () => {
  return (
    <>
      <div className="team-body">
        <div className="details-page-sub_heading">
          <h4>QAC Committee</h4>
        </div>
        <div className="team-content">
          <div className="team-heading">
            <h4>Quality Assurance Committee (QAC)</h4>
            <p>
              As per the requirement of Quality Assurance Unit of the UGC, BNQF
              and the BAC following committees for ensuring the overall quality
              assurance at ISU have been formed and approval by the ISU
              authority.
            </p>
          </div>
          <hr />
          <div className="qac-table">
            <table>
              <tr>
                <th width="5%">No</th>
                <th width="45%">Name</th>
                <th width="40%">Membership</th>
                <th width="10%">Image</th>
              </tr>
              <tr>
                <td>1</td>
                <td>Prof. Dr. Abdul Awal Khan</td>
                <td>Chair</td>
                <td>
                  <img src={vc} alt="qac" width={100} height={100} />
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td>Prof. Dr. Md. Ekramul Hoque</td>
                <td>Director</td>
                <td>
                  <img src={member2} alt="qac" width={100} height={100} />
                </td>
              </tr>
              <tr>
                <td>3</td>
                <td>Prof. Dr. Monjur Morshed Mahmud</td>
                <td>Member</td>
                <td>
                  <img src={member9} alt="qac" width={100} height={100} />
                </td>
              </tr>
              <tr>
                <td>4</td>
                <td>Dr. Md. Abul Kalam Azad</td>
                <td>Member</td>
                <td>
                  <img src={member1} alt="qac" width={100} height={100} />
                </td>
              </tr>
              <tr>
                <td>5</td>
                <td>Prof. Dr. Khandoker Saif Uddin</td>
                <td>Member</td>
                <td>
                  <img src={member3} alt="qac" width={100} height={100} />
                </td>
              </tr>
              <tr>
                <td>6</td>
                <td>Syed Mustafizur Rahman Chowdhury</td>
                <td>Member</td>
                <td>
                  <img src={member4} alt="qac" width={100} height={100} />
                </td>
              </tr>
              <tr>
                <td>7</td>
                <td>Dr. Muhammad Kamruzzaman</td>
                <td>Member</td>
                <td>
                  <img src={member5} alt="qac" width={100} height={100} />
                </td>
              </tr>
              <tr>
                <td>8</td>
                <td>Mohammad Faizullah Kawshik</td>
                <td>Member</td>
                <td>
                  <img src={member6} alt="qac" width={100} height={100} />
                </td>
              </tr>
              <tr>
                <td>9</td>
                <td>A.R.M Ataur Rahman Bhuiyan</td>
                <td>Member</td>
                <td>
                  <img src={member7} alt="qac" width={100} height={100} />
                </td>
              </tr>
              <tr>
                <td>10</td>
                <td>Monirul Hasan Masum</td>
                <td>Member Secretary</td>
                <td>
                  <img src={member8} alt="qac" width={100} height={100} />
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <QacTeam></QacTeam>
    </>
  );
};

export default QAC;
