import React from 'react'
import './IQACTeam.css'
import QacTeam from '../qac-team/QacTeam'
import image1 from '../../assets/organogram.png'


const Organogram = () => {
  return (
    <>
        <div className='team-body'>
            <div className='details-page-sub_heading'>
              <h4>Organogram</h4>
            </div>
            <div className='organogram-body'>
              <img src={image1} alt="" />
            </div>
        </div>
        <QacTeam></QacTeam>
    </>
  )
}

export default Organogram