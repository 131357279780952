import React, { useEffect } from "react";
import Header from "./Header/Header";
import TopHeader from "./TopHeader/TopHeader";
import { Outlet } from "react-router-dom";
import Footer from "./Footer/Footer";

import { useState } from "react";
import ClockLoader from "react-spinners/ClockLoader";

const MainBody = () => {
  let [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return (
    <>
      {loading ? (
        <div className="main_loader">
          <ClockLoader color="#3637d6" loading={loading} size={50} />
          <p className="mt-5">International Standard University</p>
        </div>
      ) : (
        <div>
          <TopHeader></TopHeader>
          <Header></Header>
          <Outlet></Outlet>
          <Footer></Footer>
        </div>
      )}
    </>
  );
};

export default MainBody;
