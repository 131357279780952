import React from "react";
import "./IQACTeam.css";
import QacTeam from "../qac-team/QacTeam";
import member4 from "../../assets/syed-mustafizur-rahman-chowdhury.jpg";
import member5 from "../../assets/Dr. Md. Abul Kalam Azad.jpg";
import member9 from "../../assets/engr-md-abdul-based-miah.png";
import member6 from "../../assets/mohammad-ali.jpg";
import member3 from "../../assets/s.m-naser-iqbal.jpg";
import member7 from "../../assets/md-mahbubur-rahman.png";
import member8 from "../../assets/md-mujibur-rahman-majumder.jpg";
import member10 from "../../assets/taspia-salam.jpg";
import member11 from "../../assets/mushfika-rahman.jpg";
import member12 from "../../assets/snigdha.jpg";
import member14 from "../../assets/jaglul-haque-mridha.jpg";
import member15 from "../../assets/shamima-siddika.png";

const PSAC = () => {
  return (
    <>
      <div className="team-body">
        <div className="details-page-sub_heading">
          <h4>PSAC Committee</h4>
        </div>
        <div className="team-content">
          <div className="team-heading">
            <h4>Program self Assessment committee (PSAC)</h4>
          </div>
          <hr />
          <div className="qac-table">
            <div className="table-content">
              <h4>Department of Business Administration</h4>
            </div>
            <table>
              <tr>
                <th width="5%">No</th>
                <th width="35%">Name</th>
                <th width="35%">Designation</th>
                <th width="15%">Position</th>
                <th width="10%">Image</th>
              </tr>
              <tr>
                <td>1</td>
                <td>Prof. Mohammad Ali</td>
                <td>Professor, Department of Business Administration</td>
                <td>Chair</td>
                <td>
                  <img src={member6} alt="qac" width={100} height={100} />
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td>Md. Mahbubur Rahman</td>
                <td>
                  Associate Professor, Department of Business Administration
                </td>
                <td>Member</td>
                <td>
                  <img src={member7} alt="qac" width={100} height={100} />
                </td>
              </tr>
              <tr>
                <td>3</td>
                <td>S. M. Naser Iqbal</td>
                <td>
                  Assistant Professor, Department of Business Administration
                </td>
                <td>Member</td>
                <td>
                  <img src={member3} alt="qac" width={100} height={100} />
                </td>
              </tr>
            </table>
          </div>
          <div className="qac-table">
            <div className="table-content">
              <h4>Department of Computer Science & Engineering</h4>
            </div>
            <table>
              <tr>
                <th width="5%">No</th>
                <th width="35%">Name</th>
                <th width="35%">Designation</th>
                <th width="15%">Position</th>
                <th width="10%">Image</th>
              </tr>
              <tr>
                <td>1</td>
                <td>Syed Mustafizur Rahman Chowdhury</td>
                <td>
                  Associate Professor & Chairperson, Department of Computer
                  Science & Engineering
                </td>
                <td>Chair</td>
                <td>
                  <img src={member4} alt="qac" width={100} height={100} />
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td>Md. Mujibur Rahman Majumder</td>
                <td>
                  Assistant Professor, Department of Computer Science &
                  Engineering
                </td>
                <td>Member</td>
                <td>
                  <img src={member8} alt="qac" width={100} height={100} />
                </td>
              </tr>
              <tr>
                <td>3</td>
                <td>Taspia Salam</td>
                <td>
                  Senior Lecturer, Department of Computer Science & Engineering
                </td>
                <td>Member</td>
                <td>
                  <img src={member10} alt="qac" width={100} height={100} />
                </td>
              </tr>
            </table>
          </div>
          <div className="qac-table">
            <div className="table-content">
              <h4>Department of English</h4>
            </div>
            <table>
              <tr>
                <th width="5%">No</th>
                <th width="35%">Name</th>
                <th width="35%">Designation</th>
                <th width="15%">Position</th>
                <th width="10%">Image</th>
              </tr>
              <tr>
                <td>1</td>
                <td>Dr. Md. Abul Kalam Azad</td>
                <td>
                  Associate Professor & Chairperson, Department of English
                </td>
                <td>Chair</td>
                <td>
                  <img src={member5} alt="qac" width={100} height={100} />
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td>Sultana Musfika Rahman</td>
                <td>
                  Senior Lecturer & Coordinator, B.A in English, Department of
                  English
                </td>
                <td>Member</td>
                <td>
                  <img src={member11} alt="qac" width={100} height={100} />
                </td>
              </tr>

              <tr>
                <td>3</td>
                <td>Snigdhya Hussain</td>
                <td>Senior Lecturer, Department of English</td>
                <td>Member</td>
                <td>
                  <img src={member12} alt="qac" width={100} height={100} />
                </td>
              </tr>
            </table>
          </div>
          <div className="qac-table">
            <div className="table-content">
              <h4>Department of Textile Engineering</h4>
            </div>
            <table>
              <tr>
                <th width="5%">No</th>
                <th width="35%">Name</th>
                <th width="35%">Designation</th>
                <th width="15%">Position</th>
                <th width="10%">Image</th>
              </tr>
              <tr>
                <td>1</td>
                <td>Engr. Md. Abdul Based Miah</td>
                <td>
                  Professor & Chairperson, Department of Textile Engineering
                </td>
                <td>Chair</td>
                <td>
                  <img src={member9} alt="qac" width={100} height={100} />
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td>Jaglul Haque Mridha</td>
                <td>Assistant Professor, Department of Textile Engineering</td>
                <td>Member</td>
                <td>
                  <img src={member14} alt="qac" width={100} height={100} />
                </td>
              </tr>
              <tr>
                <td>3</td>
                <td>Shamima Siddika</td>
                <td>Assistant Professor, Department of Textile Engineering</td>
                <td>Member</td>
                <td>
                  <img src={member15} alt="qac" width={100} height={100} />
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <QacTeam></QacTeam>
    </>
  );
};

export default PSAC;
